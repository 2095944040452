export const domain = `https://${process.env.NEXT_PUBLIC_WWW_DOMAIN}`;

export const images = [
  {
    url: `${domain}/images/open-graph-logo-large-br.png?v=3`,
    width: 1200,
    height: 630,
  },
  {
    url: `${domain}/images/open-graph-logo.png?v=2`,
    width: 300,
    height: 300,
  },
];

export const baseBreadcrumb = [{
  position: 1,
  name: 'Início',
  item: `${domain}/`,
}];

export const corporateContactData = {
  url: domain,
  logo: `${domain}/images/nu-icon.png?v=2`,
  contactPoint: [{
    telephone: '+55 0800 608 6236',
    contactType: 'customer service',
    contactOption: 'TollFree',
    areaServed: 'BR',
    availableLanguage: ['Brazilian Portuguese'],
  }],
};
