import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const SearchContext = React.createContext({
  isSearchOpen: false,
  openSearch: () => {},
  closeSearch: () => {},
});

export const useSearchContext = () => (useContext(SearchContext));

export default function SearchContextProvider({ children }) {
  const [isSearchOpen, setSearchOpenState] = useState(false);

  const openSearch = () => setSearchOpenState(true);
  const closeSearch = () => setSearchOpenState(false);

  return (
    <SearchContext.Provider value={{
      isSearchOpen,
      openSearch,
      closeSearch,
    }}
    >
      {children}
    </SearchContext.Provider>
  );
}

SearchContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
