import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';

import SEO from '@nubank/www-latam-commons/components/SEO/SEO';
import filterOutCurrentLocaleRoutes from '@nubank/www-latam-commons/utils/i18n/filterOutCurrentLocaleRoutes';
import {
  DEFAULT_LOCALE,
} from 'utils/i18nUtils';
import routes from 'pages/routes.json';
import {
  domain,
  images,
  baseBreadcrumb,
  corporateContactData,
} from 'utils/seo';

import { cmsPageKeyByRouteKey } from '../../services/cms/cmsService';

const formatMessageWithDefaultValue = (routeKey, intlKey) => {
  const intl = useIntl();
  const defaultContent = intl.formatMessage({ id: `HOME.${intlKey}` });

  const key = intl.formatMessage({
    id: `${routeKey}.${intlKey}`,
    defaultMessage: defaultContent,
  });

  return key;
};

const SEOWithIntl = ({ routeKey }) => {
  const { formatMessage } = React.useContext(NuDsContext);
  const intl = useIntl();

  const {
    noindex,
    nofollow,
    routes: routesByLocale,
    canonicals = {},
    alternate,
  } = routes[routeKey];

  const allRoutesByPage = { ...routesByLocale, ...alternate };
  const canonicalPage = canonicals[intl.locale];
  const pageTitle = formatMessage({ id: `cms.${cmsPageKeyByRouteKey[routeKey]}.headSeo.title`, optional: true })
  || formatMessageWithDefaultValue(routeKey, 'HEAD.TITLE');
  const description = formatMessage({ id: `cms.${cmsPageKeyByRouteKey[routeKey]}.headSeo.description`, optional: true })
  || formatMessageWithDefaultValue(routeKey, 'HEAD.META_DESCRIPTION');
  const breadcrumbTitle = formatMessage({ id: `cms.${cmsPageKeyByRouteKey[routeKey]}.headSeoBreadcrumbTitle`, optional: true })
  || formatMessageWithDefaultValue(routeKey, 'HEAD.BREADCRUMB_TITLE');

  const alternatePages = filterOutCurrentLocaleRoutes(
    allRoutesByPage, intl.locale || DEFAULT_LOCALE,
  );

  return (
    <SEO
      pageTitle={pageTitle}
      description={description}
      breadcrumbTitle={breadcrumbTitle}
      alternatePages={alternatePages}
      canonicalPage={canonicalPage}
      canonicals={canonicals}
      noindex={noindex}
      nofollow={nofollow}
      allRoutesByPage={allRoutesByPage}
      domain={domain}
      images={images}
      baseBreadcrumb={baseBreadcrumb}
      corporateContactData={corporateContactData}
    />
  );
};

SEOWithIntl.defaultProps = {
  routeKey: 'HOME',
};

SEOWithIntl.propTypes = {
  routeKey: PropTypes.string,
};

export default SEOWithIntl;
